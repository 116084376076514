<template>
  <el-dialog
      :visible.sync="dialogVisible"
      :class="[
        { 'full-window': activeDialog !== 'AddEditFAQ' && 
                          activeDialog !== 'BusinessAccountData' && 
                          activeDialog !== 'BusinessAccountClients' && 
                          activeDialog !== 'UserData' && 
                          activeDialog !== 'FundsInfo' && 
                          activeDialog !== 'GetUsersXLS' && 
                          activeDialog !== 'GetPromoCodes' && 
                          activeDialog !== 'MarkData' && 
                          activeDialog !== 'GetBusinessXLS' && 
                          activeDialog !== 'TransactionsXLS' && 
                          activeDialog !== 'PromoData' && 
                          activeDialog !== 'ReducedСommissions' && 
                          activeDialog !== 'AddDocument' &&
                          activeDialog !== 'SetCommission' && 
                          activeDialog !== 'ReferralData'
        },
        { 'full-window--small' : activeDialog === 'AddEditUser' || 
                                  activeDialog === 'EditingLimit' || 
                                  activeDialog === 'AdditionalManager' || 
                                  activeDialog === 'GetUsersXLS' || 
                                  activeDialog === 'MarkData' || 
                                  activeDialog === 'TransactionsXLS' || activeDialog === 'AddEditRepresentative'},
        { 'no-padding' : activeDialog === 'ReducedСommissions'},
      ]"
      :before-close="handleClose"
     >
    <component :is="activeDialog" />
  </el-dialog>
</template>

<script>
export default {
  name: 'Dialog',
  components: {
    BindCreative: () => import('../dialog/BindCreative.vue'),
    AddInitialContract: () => import('../dialog/AddInitialContract'),
    AddAct: () => import('../dialog/AddAct'),
    AddCreative: () => import('../dialog/AddCreative'),
    AddFinalContract: () => import('../dialog/AddFinalContract'),
    CheckingFinalContract: () => import('../dialog/CheckingFinalContract'),
    AddClient: () => import('../dialog/AddClient'),
    AddEditFAQ: () => import('../dialog/AddEditFAQ.vue'),
    Prepaid: () => import('../dialog/Prepaid'),
    Cashback: () => import('../dialog/Cashback'),
    Deposit: () => import('../dialog/Deposit'),
    AddAccount: () => import('../dialog/AddAccount'),
    BusinessAccountData: () => import('../dialog/BusinessAccountData'),
    GetUsersXLS: () => import('../dialog/GetUsersXLS'),
    GetPromoCodes: () => import('../dialog/GetPromoCodes.vue'),
    UserData: () => import('../profile/UserData'),
    BusinessAccountClients: () => import('../dialog/BusinessAccountClients'),
    AddEditUser: () => import('../dialog/AddEditUser'),
    EditingLimit: () => import('../dialog/EditingLimit'),
    AdditionalManager: () => import('../dialog/AdditionalManager'),
    AddVkUser: () => import('../dialog/AddVkUser'),
    AddVkAdds: ()=> import('../dialog/AddVkAdds'),
    NewApplication: () => import('../dialog/NewApplication'),
    ReferenceValues: () => import('../dialog/ReferenceValues'),
    FundsInfo: () => import('../dialog/FundsInfo'),
    MarkData: () => import('../dialog/MarkData'),
    ReducedСommissions: () => import('../dialog/ReducedСommissions'),
    GetBusinessXLS: () => import('../dialog/GetBusinessXLS'),
    TransactionsXLS: () => import('../dialog/TransactionsXLS'),
    PromoData: () => import('../dialog/PromoData'),
    AddDocument: () => import('../dialog/AddDocument'),
    SetCommission: () => import('../dialog/SetCommission'),
    AddEditRepresentative: () => import('../dialog/AddEditRepresentative'),
    ReferralData: () => import('../dialog/ReferralData'),
    Points: () => import('../dialog/Points'),
  },
  data() {
    return {
      clickStartElement: null,
    }
  },
  mounted() {
    const wrappers = document.querySelectorAll('.el-dialog__wrapper')
    if (wrappers && wrappers.length) {
      Array.from(wrappers).forEach((wrapper) => {
        wrapper.addEventListener('mousedown', (e) => {
          this.clickStartElement = e.target.classList.contains('el-dialog__wrapper') || e.target.classList.contains('el-dialog__close')
        }, true)
      })
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.$store.state.Dialog.dialogVisible
      },
      set(val) {
        this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: val })
        setTimeout(() => {
          this.$store.commit('Users/setData', { label: 'editUser', data: '' })
          this.$store.commit('promo/setData', { label: 'editPromo', data: '' })
          this.$store.commit('Dialog/setData', { label: 'promoTrigger', data: '' })
          this.$store.commit('Dialog/setData', { label: 'activeDialog', data: '' })
          this.$store.commit('Dialog/setData', { label: 'isEditable', data: false })
          this.$store.commit('Dialog/setData', { label: 'baActivePlatform', data: null })
          this.$store.commit('Dialog/setData', { label: 'dialogTitle', data: '' })
        }, 200)
      }
    },
    activeDialog() {
      return this.$store.state.Dialog.activeDialog
    }
  },
  methods: {
    handleClose(done) {
      if (this.clickStartElement) {
        this.$store.commit('Dialog/setData', { label: 'isOpenUL', data: false })
        done();
      }
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/mixin";

.el-dialog {
  width: 67.5rem;

  &__headerbtn {
    font-size: 3.2rem;
  }

  &__header {
    padding: 0;
    z-index: 99;
    position: relative;
  }

  .el-dialog__headerbtn:focus .el-dialog__close,
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: var(--primary);
  }
}

.full-window {
  .el-dialog {
    margin: 0 !important;
    width: 50%;
    float: right;
    border-radius: 0;

    &__body {
      padding: 0;
    }
  }

  &--small {

    .el-dialog {
      width: 35%;

      @include below(993px) {
        width: 45%;

        &__headerbtn {
          top: 16px;
          right: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      @include below(768px) {
        width: 60%;
      }

      @include below(576px) {
        width: 100%;

        &__headerbtn {
          top: 12px;
          right: 12px;
        }
      }
    }
  }
}

.no-padding {
  .el-dialog__body {
    padding: 0;
  }

  .el-dialog__headerbtn {
    top: 6px;
    right: 6px;
  }
}

@include below(993px) {
  .full-window .el-dialog {
    width: 100%;
    .deposit__final,
    .prepaid__final{
      padding-bottom: 50px;
    }
  }
}
</style>
